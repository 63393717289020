import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import AppRouter from 'Routes';
import GraphqlProvider from 'Gql';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import GlobalLoading from 'Components/Global/GlobalLoading';
import { useDispatch, useSelector } from 'react-redux';
import { changeTrigger } from 'Features/login';
import { IsB2BRoute } from 'B2B/Utils/IsB2B';
import { BrowserRouter } from 'react-router-dom';

/**
 *  @returns { ReactElement | any}  Main App File For This Project
 */
function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const [loginOpened, setLoginOpened] = useState(false);
  const time = useSelector((state) => state.storeConfig.auto_login_popup);
  const loginState = useSelector((state) => state.login.trigger);

  window.scrollTo({
    top: 0,
    left: 0,
  });

  useEffect(() => {
    const handleBackNavigation = () => {
      document.body.classList.remove('disable-scroll');
    };
    window.addEventListener('popstate', handleBackNavigation);
    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);

  console.log(!IsB2BRoute());

  useEffect(() => {
    if (!IsB2BRoute() && !token && !loginOpened && !loginState) {
      const timeout = setTimeout(() => {
        if (time && !IsB2BRoute()) {
          dispatch(changeTrigger());
          setLoginOpened(true);
        }
      }, time * 1000);

      return () => clearTimeout(timeout);
    }
  }, [time]);

  return (
    <>
      <GlobalLoading />
      <GraphqlProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </GraphqlProvider>
      <ToastContainer />
    </>
  );
}

export default App;
